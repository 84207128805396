export const CityPhoneNumberData: {
  [city: string]: {
    phoneNumber: string
    phoneNumberTxt: string
  }
} = {
  default: {
    phoneNumberTxt: '(855) 800-5629',
    phoneNumber: '8558005629',
  },
  generalValvoline: {
    phoneNumberTxt: '(855) 643-8773',
    phoneNumber: '8556438773',
  },
  austin: {
    phoneNumberTxt: '(512) 640-2560',
    phoneNumber: '5126402560',
  },
  'san-antonio': {
    phoneNumberTxt: '(210) 794-6444',
    phoneNumber: '2107946444',
  },
  sanantonio: {
    phoneNumberTxt: '(210) 794-6444',
    phoneNumber: '2107946444',
  },
  dallas: {
    phoneNumberTxt: '(469) 706-3497',
    phoneNumber: '4697063497',
  },
  'fort-worth': {
    phoneNumberTxt: '(469) 706-3497',
    phoneNumber: '4697063497',
  },
  houston: {
    phoneNumberTxt: '(832) 706-4115',
    phoneNumber: '8327064115',
  },
  atlanta: {
    phoneNumberTxt: '(470) 467-7736',
    phoneNumber: '4704677736',
  },
  nashville: {
    phoneNumberTxt: '(629) 206-1979',
    phoneNumber: '6292061979',
  },
  tampa: {
    phoneNumberTxt: '(813) 957-9076',
    phoneNumber: '8139579076',
  },
  jacksonville: {
    phoneNumberTxt: '(904) 867-4476',
    phoneNumber: '9048674476',
  },
  orlando: {
    phoneNumberTxt: '(689) 216-4829',
    phoneNumber: '6892164829',
  },
  miami: {
    phoneNumberTxt: '(786) 780-2495',
    phoneNumber: '7867802495',
  },
  chicago: {
    phoneNumberTxt: '(773) 242-6985',
    phoneNumber: '7732426985',
  },
}
