import * as React from 'react'
import { useSource } from '../../../hooks/useSource'
import { WizardVariation } from './WizardVariation'
import { WizardInline } from '../../Builder/components/WizardInline'
import VehicleInfoFormSection, { twVehicleInfoValidationSchema } from './VehicleInfoFormSection'
import { PersonalInfoValidationSchema, NoEmailPersonalInfoFormSectionInline } from './NoEmailPersonalInfoFormSection'
import { createInquiryRequest } from '../scheduleServiceFormSubmit'
import { catchAll } from '../../../util/quote-form-utils'
import Router from 'next/router'
import { paramCase } from 'param-case'
import { pipe, split } from 'ramda'
import { capitalCase } from 'capital-case'
import { sendGAEvent } from '../../../util/util'
import { FormikHelpers } from 'formik'
import { VehicleInfoFormSectionInline } from './VehicleInfoFormSection'
import { generateFakeEmail } from '../../../util/form-util'
import { LeadExtraFields, getExtraHiddenFieldValues } from '../../Fields/LeadExtraFields'

export const twDefaultInitialWizardFormValues = {
  'form-name': 'scheduleServiceWizard',
  fullName: '',
  email: generateFakeEmail(null),
  extraInfo: '',
  vehicleYear: '',
  phoneNumber: '',
  zip: '',
  hearAboutUs: '',
  make: '',
  model: '',
  symptoms: '',
  referredById: '',
  source: '',
  serviceUrgency: '',
  referrerSource: '',
}

export type WizardScheduleServiceValues = {
  fullName: string
  'form-name': string
  email: string
  phoneNumber: string
  vehicleYear: string
  zip: string
  make: string
  model: string
  extraInfo: string
  source: string
  referredById: string
  hearAboutUs: string
  symptoms: string
  serviceUrgency: string
  referrerSource: string
}
export const mapFullNameToFirstLast = (fullName: string) => {
  const nameArr = pipe(capitalCase, split(' '))(fullName)
  let firstName = '',
    lastName = ''
  if (nameArr.length > 0) {
    firstName = nameArr[0]
    if (nameArr.length > 1) {
      lastName = nameArr[1]
      for (let i = 2; i < nameArr.length; i++) {
        lastName = `${lastName} ${nameArr[i]}`
      }
    }
  }
  return { firstName, lastName }
}
// for source if undefined return an empty string rather than the undefined
export const NoEmailScheduleServiceFormMultiStep: React.FC<{
  initialValues?: WizardScheduleServiceValues
}> = ({ initialValues = twDefaultInitialWizardFormValues }) => {
  const source = useSource()

  return (
    <WizardVariation
      initialValues={{ ...initialValues, source }}
      formPages={[
        {
          element: VehicleInfoFormSection,
          validationSchema: twVehicleInfoValidationSchema,
        },
      ]}
    />
  )
}

export const scheduleServiceFormMultiStepSubmit: (
  values: WizardScheduleServiceValues,
  formikActions: FormikHelpers<any>
) => void = async (values) => {
  await sendGAEvent('ScheduleServiceForm', 'submitted', values['form-name'])
  const { firstName } = mapFullNameToFirstLast(values.fullName)
  const extraFieldsValues = getExtraHiddenFieldValues()
  const valuesWithExtraFields = {
    ...values,
    extraFields: extraFieldsValues,
  }
  const results = await Promise.allSettled([
    createInquiryRequest(valuesWithExtraFields),
    catchAll(valuesWithExtraFields),
  ])
  const inquiryId = results[0].status === 'fulfilled' ? results?.[0]?.value?.data?.createInquiryRequest?.id : undefined
  if (inquiryId) {
    sendGAEvent('ScheduleServiceFormSuccess', 'submitSuccess', values['form-name'])
  }
  const { make, model, vehicleYear } = values
  const currUTMS = Router.query
  await Router.push({
    pathname: `/success`,
    query: {
      vehicleMake: paramCase(make),
      vehicleModel: paramCase(model),
      vehicleYear,
      firstName: firstName,
      inquiry: inquiryId,
      ...currUTMS,
    },
  }).then(() => window.scrollTo(0, 0))
}

export const NoEmailScheduleServiceFormMultiStepCompact: React.FC<{
  initialValues?: WizardScheduleServiceValues
  linkTarget?: string
  buttonText?: string
  isLinkForm: boolean
}> = ({ initialValues = twDefaultInitialWizardFormValues, isLinkForm, linkTarget, buttonText }) => {
  const source = useSource()

  return (
    <>
      <WizardInline
        isLinkForm={isLinkForm}
        linkTarget={linkTarget}
        buttonText={buttonText}
        initialValues={{ ...initialValues, source }}
        onSubmit={scheduleServiceFormMultiStepSubmit}
        formPages={[
          {
            element: VehicleInfoFormSectionInline,
            validationSchema: twVehicleInfoValidationSchema,
          },
          {
            element: NoEmailPersonalInfoFormSectionInline,
            validationSchema: PersonalInfoValidationSchema,
          },
        ]}
      />
      <LeadExtraFields />
    </>
  )
}
