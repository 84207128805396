import { Field, Form, Formik, FormikProps } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { ObjectSchema } from 'yup'
import { useFormAnalytics } from '../../../hooks/useFormAnalytics'
import { Button } from '../../Button'
import { FunctionComponent, useState } from 'react'
import React from 'react'

export interface ValidationElement extends Element {
  validationSchema: any
}

export interface FormPages {
  element: FunctionComponent<any>
  validationSchema: ObjectSchema<any>
}

export type WizardScheduleServiceValues = {
  fullName: string
  'form-name': string
  email: string
  phoneNumber: string
  vehicleYear: string
  zip: string
  make: string
  model: string
  extraInfo: string
  source: string
  referredById: string
  hearAboutUs: string
  symptoms: string
  serviceUrgency: string
  referrerSource: string
}

export const Wizard: React.FC<{
  initialValues: WizardScheduleServiceValues
  onSubmit: any
  title?: string
  subtitle?: string
  formPages: FormPages[]
}> = ({ initialValues, onSubmit, formPages, title, subtitle }) => {
  const [page, setPage] = useState(0)
  const activePage = formPages[page].element
  const length = formPages.length
  const validationSchema = formPages[page].validationSchema
  const goNextPage = () => {
    setPage(page + 1)
  }

  return (
    <div className={'text-left text-black'}>
      <div className="text-center sm:mb-1">
        <h3 className="mb-1">{title}</h3>
        <p className="mt-0 text-base lg:leading-none leading-tight">{subtitle}</p>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <InnerForm {...props} activePage={activePage} goNextPage={goNextPage} page={page} length={length} />
        )}
      </Formik>
    </div>
  )
}

const InnerForm: React.FC<
  FormikProps<any> & {
    page: number
    activePage: any
    goNextPage: () => void
    length: number
  }
> = (props) => {
  const { values, isSubmitting, isValid, page, activePage, goNextPage, length, setFieldValue } = props

  useFormAnalytics(values)

  return (
    <Form name="scheduleServiceWizard">
      <Field type="hidden" name="form-name" value={'scheduleServiceWizard'} />
      <Field type="hidden" name="email" />

      {React.createElement(activePage, props)}
      <div className="px-6">
        {page === length - 1 ? (
          <SubmitButton
            isSubmitting={isSubmitting}
          />
        ) : (
          <Button
            disabled={!isValid}
            className="w-full py-3"
            onClick={async () => {
              if (window['dataLayer']) {
                window['dataLayer'].push({ event: 'nextClicked' })
              }
              setFieldValue('referrerSource', document.referrer, false)
              goNextPage()
            }}
          >
            Next
          </Button>
        )}
      </div>
    </Form>
  )
}

export const SubmitButton: React.FC<{
  isSubmitting: boolean
  buttonText?: string
  onClick?: (e?: any) => void
}> = ({ isSubmitting, buttonText, onClick }) => {
  return (
    <Button className="w-full py-3" onClick={e => onClick ? onClick(e) : null} disabled={isSubmitting} type={'submit'} id={'submit-btn'}>
      {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : buttonText ? buttonText : `Get Free Quote`}
    </Button>
  )
}
