import { useEffect, useState } from 'react'
import { getMakesByYear, getModelsByYearAndMake } from '../util/quote-form-utils'

export const useMakeModelYear = (setFieldValue: any, values: any) => {
  const [modelOptions, setModelOptions] = useState([''])
  const [makeOptions, setMakeOptions] = useState([''])
  useEffect(() => {
    setFieldValue('model', '')
    // @ts-ignore
    if (values.make && values.vehicleYear) {
      setModelOptions(getModelsByYearAndMake(values.vehicleYear, values.make))
    }
  }, [values.make])
  useEffect(() => {
    setFieldValue('make', '')
    // @ts-ignore
    if (values.vehicleYear) {
      setMakeOptions(getMakesByYear(values.vehicleYear))
    }
  }, [values.vehicleYear])
  return { modelOptions, makeOptions }
}
