import React from 'react'
import { Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { object } from 'yup'
import { getYears } from '../../../util/quote-form-utils'
import {
  CustomMakeField,
  CustomSelectField,
  CustomInlineSelectField,
  CustomInlineMakeField,
  CustomInlineModelField,
} from '../../Fields/CustomSelectField'
import { useMakeModelYear } from '../../../hooks/useMakeModelYear'

const VehicleInfoFormSection: React.FC<FormikProps<any>> = (props) => {
  const { setFieldValue, values } = props
  const { makeOptions, modelOptions } = useMakeModelYear(setFieldValue, values)

  return (
    <>
      <div className={'text-left mb-4 w-full px-2 md:px-6'}>
        <Field
          name={'vehicleYear'}
          component={CustomSelectField}
          options={getYears()}
          required={true}
        />
        <Field
          name={'make'}
          component={CustomMakeField}
          required={true}
          options={makeOptions ? makeOptions : []}
          disabled={!values.vehicleYear}
        />
        <Field
          name={'model'}
          component={CustomSelectField}
          options={modelOptions ? modelOptions : []}
          required={true}
          disabled={!(values.make && values.vehicleYear)}
        />
      </div>
    </>
  )
}

export const VehicleInfoFormSectionInline: React.FC<FormikProps<any>> = ({ setFieldValue, values }) => {
  const { makeOptions, modelOptions } = useMakeModelYear(setFieldValue, values)

  return (
    <div className="block lg:flex align-center justify-center content-center items-center self-center">
      <div className="w-full lg:w-48">
        <Field
          name={'vehicleYear'}
          component={CustomInlineSelectField}
          options={getYears()}
          required={true}
        />
      </div>
      <div className="inline-block w-1/2 lg:w-48">
        <Field
          name={'make'}
          component={CustomInlineMakeField}
          required={true}
          options={makeOptions ? makeOptions : []}
          disabled={!values.vehicleYear}
        />
      </div>
      <div className="inline-block w-1/2 lg:w-48">
        <Field
          name={'model'}
          component={CustomInlineModelField}
          options={modelOptions ? modelOptions : []}
          required={true}
          disabled={!(values.make && values.vehicleYear)}
        />
      </div>
    </div>
  )
}

export const VehicleInfoFormSectionInlineStack: React.FC<FormikProps<any>> = ({ setFieldValue, values }) => {
  const { makeOptions, modelOptions } = useMakeModelYear(setFieldValue, values)

  return (
    <div className="block">
      <div className="inline-block w-1/2">
        <Field
          name={'vehicleYear'}
          component={CustomInlineSelectField}
          options={getYears()}
          required={true}
        />
      </div>
      <div className="inline-block w-1/2">
        <Field
          name={'make'}
          component={CustomInlineMakeField}
          required={true}
          options={makeOptions ? makeOptions : []}
          disabled={!values.vehicleYear}
        />
      </div>
      <div className="inline-block w-full">
        <Field
          name={'model'}
          component={CustomInlineModelField}
          options={modelOptions ? modelOptions : []}
          required={true}
          disabled={!(values.make && values.vehicleYear)}
        />
      </div>
    </div>
  )
}

export const twVehicleInfoValidationSchema = object().shape({
  vehicleYear: Yup.string().required('Required'),
  make: Yup.string().required('Required'),
  model: Yup.string().required('Required'),
})
export default VehicleInfoFormSection
