import { applySpec, identity, map } from 'ramda'
import { randomString } from 'remeda';
export const toReactSelectOptions = (options: string[]) =>
  map(
    applySpec({
      value: identity,
      label: identity,
    })
  )(options)

  // generates ops{16digit random string}@email.com
export const generateFakeEmail = (phoneNumber: string | null = null): string => {
  if (!phoneNumber || phoneNumber.length < 10) return `${randomString(16)}@email.com`;
  return `${phoneNumber}@email.com`;
};