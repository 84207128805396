import Cookies from 'js-cookie'
import * as Sentry from '@sentry/nextjs'

type ExperimentsData = Record<
  string,
  {
    g: Record<string, number>
    v: number
  }
>

/*
 * For details on the _conv_v cookie structure, see:
 * https://support.convert.com/hc/en-us/articles/204495429-Convert-Experiences-Tracking-Cookies-Structure
 */
export const getConvertExperimentsFromCookies = (): ExperimentsData | null => {
  try {
    const convertCookie = Cookies.get('_conv_v')

    if (!convertCookie) return null

    const startsWith = (str: string, prefix: string) => str.indexOf(prefix) === 0
    const experimentsRaw = convertCookie.split('*').find((str) => startsWith(str, 'exp:'))

    if (!experimentsRaw) return null

    const experiments: ExperimentsData = JSON.parse(
      experimentsRaw
        .replace('exp:', '')
        .replace(/-/g, ',')
        .replace(/\./g, ':')
        .replace(/([a-z0-9]+):/g, '"$1":')
    )

    return experiments
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        message: 'Error parsing _conv_v cookie',
      },
      tags: {
        fn: 'getExperimentsFromCookie',
      },
    })
    return null
  }
}
