import { useEffect, useState } from 'react'
import { always, concat, difference, fromPairs, head, includes, keys, not, propOr, toPairs } from 'ramda'
import { sendGAEvent } from '../util/util'

export const useFormAnalytics = (newFormValues) => {
  const [oldFormValues, setOldFormValues] = useState(newFormValues)
  const [isInitialLoaded, setInitialLoaded] = useState(false)
  const [fieldsTracked, setFieldsTracked] = useState([])
  //TODO we should handle this better here
  const formName = propOr('unknown', 'form-name', newFormValues)

  useEffect(() => {
    try {
      if (isInitialLoaded) {
        const diffValues = fromPairs(difference(toPairs(newFormValues), toPairs(oldFormValues)))

        const fieldChanged = head(keys(diffValues))

        // detect undefined field and do not send undefined
        if (not(includes(fieldChanged, fieldsTracked)) && fieldChanged !== undefined) {
          setFieldsTracked(concat(fieldsTracked, [fieldChanged]))
          // Log to GA
          sendGAEvent('fieldChanged', String(fieldChanged), String(formName)).then(always(''))
        }
        setOldFormValues(newFormValues)
      } else {
        setInitialLoaded(true)
      }
    } catch (e) {
      console.error({ e })
    }
  }, [oldFormValues, newFormValues, isInitialLoaded, fieldsTracked, formName])
}
