export const referrals = [
  'Referred By Friend',
  'NuBrakes Van',
  'Google Maps',
  'Google Search',
  'Radio',
  'TV',
  'YouTube',
  'Yelp',
  'Facebook / Instagram',
  'Nextdoor',
  'Pandora',
  'Spotify',
  'Bing',
  'Billboard',
  'Other',
]

export const serviceUrgencyOptions = [
  'I’m looking to get my repairs done ASAP',
  'I am going to get my repairs done but not sure when',
  'I am not sure if I need repairs',
  'I am just looking for a price',
]
