import { isEmpty } from 'ramda'
import React from 'react'

const hiddenFieldsList = [
  { elementId: 'extra-field-utm_source', name: 'utm_source' },
  { elementId: 'extra-field-utm_medium', name: 'utm_medium' },
  { elementId: 'extra-field-utm_campaign', name: 'utm_campaign' },
  { elementId: 'extra-field-utm_term', name: 'utm_term' },
  { elementId: 'extra-field-gclid', name: 'gclid' },
  { elementId: 'extra-field-gaid', name: 'gaid' },
  { elementId: 'extra-field-session_id', name: 'session_id' },
  {
    elementId: 'extra-field-campaign_name',
    name: 'campaign_name',
  },
  {
    elementId: 'extra-field-adgroup_name',
    name: 'adgroup_name',
  },
  {
    elementId: 'extra-field-match_type',
    name: 'match_type',
  },
  {
    elementId: 'extra-field-network',
    name: 'network',
  },
  {
    elementId: 'extra-field-device',
    name: 'device',
  },
  {
    elementId: 'extra-field-device_model',
    name: 'device_model',
  },
  {
    elementId: 'extra-field-keyword',
    name: 'keyword',
  },
  {
    elementId: 'extra-field-creative',
    name: 'creative',
  },
]

export const getExtraHiddenFieldValues = () => {
  try {
    const values = {}
    for (const field of hiddenFieldsList) {
      const hiddenFieldValue = (document.getElementById(field.elementId) as HTMLInputElement)?.value ?? null
      values[field.name] = isEmpty(hiddenFieldValue) ? null : hiddenFieldValue
    }
    return isEmpty(values) ? null : values
  } catch (e) {
    return null
  }
}

/*
 * This component is used to add hidden fields to the lead form and to track
 * UTM params through a Google Tag Manager script.
 */
export const LeadExtraFields = () => {
  return (
    <div className="hidden absolute top-1/2 left-1/2">
      {hiddenFieldsList.map((field) => (
        <input key={field.elementId} id={field.elementId} />
      ))}
    </div>
  )
}
