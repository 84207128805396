import * as React from 'react'
import { useMemo } from 'react'
import { ErrorMessage, FieldProps, useField } from 'formik'
import * as changeCase from 'capital-case'
import CreatableSelect from 'react-select/creatable'
import { toReactSelectOptions } from '../../util/form-util'
import { comparator, lt, pipe, sort } from 'ramda'
import { CustomFormControl, Label } from './CustomTextField'

export const CustomSelectField: React.FC<
  FieldProps & { required?: boolean; options: string[]; errorTextStyle?: any }
> = ({ options, required, errorTextStyle, field, ...props }) => {
  return (
    <div className="pb-3">
      <Label htmlFor={field.name}>
        {changeCase.capitalCase(field.name)}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </Label>
      <CustomFormControl {...field} {...props} as={'select'} id={field.name}>
        <option />
        {options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </CustomFormControl>
      {!errorTextStyle ? (
        <ErrorMessage name={field.name} />
      ) : (
        <ErrorMessage name={field.name}>{(msg) => <div style={errorTextStyle}>{msg}</div>}</ErrorMessage>
      )}
    </div>
  )
}

export const CustomReferralSelectField: React.FC<
  FieldProps & { customLabel: any; required?: boolean; options: string[] }
> = ({ options, required, field, customLabel, ...props }) => {
  return (
    <div className="mb-3">
      <Label>
        {customLabel}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </Label>
      <CustomFormControl {...field} {...props} as={'select'} id={field.name}>
        <option />
        {options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </CustomFormControl>
      <ErrorMessage name={field.name} />
    </div>
  )
}

export const CustomReactSelectField: React.FC<{
  name: string
  required?: boolean
  options: string[]
  customLabel?: string
}> = ({ customLabel, options, required, name }) => {
  const [field, , helper] = useField(name)
  const reactSelectOptions = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => pipe(sort(comparator(lt)), toReactSelectOptions)(options),
    []
  )
  return (
    <>
      <Label htmlFor={field.name}>
        {customLabel ? customLabel : changeCase.capitalCase(field.name)}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </Label>
      <CreatableSelect
        id={field.name}
        options={reactSelectOptions}
        onChange={(optionObject) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { value } = optionObject
          helper.setValue(value)
        }}
      />
      <ErrorMessage render={(errorMessage) => <div style={{ color: 'red' }}>{errorMessage}</div>} name={field.name} />
    </>
  )
}

export const CustomMakeField: React.FC<FieldProps & { required?: boolean; options: string[] }> = ({
  options,
  required,
  field,
  ...props
}) => {
  return (
    <div className="mb-3">
      <Label htmlFor={field.name}>
        {changeCase.capitalCase(field.name)}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </Label>
      <CustomFormControl {...field} {...props} as={'select'} id={field.name}>
        <option />
        {options.map((option, index) => (
          <option value={option} key={index}>
            {changeCase.capitalCase(option)}
          </option>
        ))}
      </CustomFormControl>
      <ErrorMessage name={field.name} />
    </div>
  )
}

export const CustomInlineSelectField: React.FC<FieldProps & { required?: boolean; options: string[] }> = ({
  options,
  required,
  field,
  ...props
}) => {
  // has default option then lets pipe defaultValue
  return (
    <div className="custom-chevron align-center m-2 pl-0">
      <CustomFormControl {...field} {...props} as={'select'} id={field.name}>
        <option value="" disabled>
          Vehicle Year {required ? '*' : ''}
        </option>
        {options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </CustomFormControl>
      <ErrorMessage name={field.name} />
    </div>
  )
}

export const CustomInlineModelField: React.FC<FieldProps & { required?: boolean; options: string[] }> = ({
  options,
  required,
  field,
  ...props
}) => {
  return (
    <div className="align-center m-2">
      <CustomFormControl {...field} {...props} as={'select'} id={field.name}>
        <option value="" disabled>
          Model {required ? '*' : ''}
        </option>
        {options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </CustomFormControl>
      <ErrorMessage name={field.name} />
    </div>
  )
}

export const CustomInlineMakeField: React.FC<FieldProps & { required?: boolean; options: string[] }> = ({
  options,
  required,
  field,
  ...props
}) => {
  return (
    <div className="align-center mx-2">
      <CustomFormControl {...field} {...props} as={'select'} id={field.name}>
        <option value="" disabled>
          Make {required ? '*' : ''}
        </option>
        {options.map((option, index) => (
          <option value={option} key={index}>
            {changeCase.capitalCase(option)}
          </option>
        ))}
      </CustomFormControl>
      <ErrorMessage name={field.name} />
    </div>
  )
}
