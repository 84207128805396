import { useRouter } from 'next/router'
import { getSourceFromPathname, getSourceFromUTM } from '../util/util'
import { prop } from 'ramda'

export const useSource = () => {
  const router = useRouter()
  const query = router.query
  const pathname = router.pathname
  const source = prop('utm_source', query)
  const medium = prop('utm_medium', query)
  const sourceMedium = `${source} / ${medium}`
  const sourceFromUtm = getSourceFromUTM(sourceMedium)
  return sourceFromUtm ? sourceFromUtm : getSourceFromPathname(pathname)
}
