import { Field, Form, Formik, FormikProps } from 'formik'
import { useFormAnalytics } from '../../../hooks/useFormAnalytics'
import { Button } from '../../Button'
import { useState } from 'react'
import React from 'react'
import { FormPages, SubmitButton } from './Wizard'
import { WizardScheduleServiceValues } from '../../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import { serializeLinkWithParams } from '../../../util/util'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { CUSTOMER_WEBAPP_URL } from '../../../pages/_app'

const goToLink = (link) => window['location'].assign(link)

export const WizardInline: React.FC<{
  initialValues: WizardScheduleServiceValues
  onSubmit: any
  title?: string
  subtitle?: string
  isLinkForm: boolean
  linkTarget?: string
  buttonText?: string
  formPages: FormPages[]
}> = ({ initialValues, onSubmit, formPages, isLinkForm, linkTarget, buttonText }) => {
  const [page, setPage] = useState(0)
  const activePage = formPages[page].element
  const length = formPages.length
  const validationSchema = formPages[page].validationSchema
  const goNextPage = () => {
    setPage(page + 1)
  }
  return (
    <div className={'text-left text-black'}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        linkTarget={linkTarget}
        isLinkForm={isLinkForm}
        buttonText={buttonText}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <InnerFormInline
            {...props}
            isLinkForm={isLinkForm}
            buttonText={buttonText}
            activePage={activePage}
            linkTarget={linkTarget}
            goNextPage={goNextPage}
            page={page}
            length={length}
          />
        )}
      </Formik>
    </div>
  )
}

const InnerFormInline: React.FC<
  FormikProps<any> & {
    page: number
    activePage: any
    goNextPage: () => void
    length: number
    isLinkForm: boolean
    linkTarget?: string
    buttonText: string
  }
> = (props) => {
  const {
    values,
    isSubmitting,
    isValid,
    page,
    activePage,
    goNextPage,
    length,
    setFieldValue,
    isLinkForm,
    linkTarget,
    buttonText,
  } = props

  useFormAnalytics(values)
  const query = useQueryParam()
  const ymmValues = {
    vehicleYear: values?.vehicleYear,
    vehicleMake: values?.make,
    vehicleModel: values?.model,
  }
  return (
    <Form name="scheduleServiceWizard" className="fomo_instant">
      <Field type="hidden" name="form-name" value={'scheduleServiceWizard'} />
      <Field type="hidden" name="email" />
      <div className="lg:flex flex-wrap block align-center justify-center content-center items-center self-center">
        {React.createElement(activePage, props)}
        <div className="w-full lg:w-48 px-2 mt-2 sm:mt-0">
          {page === length - 1 && isLinkForm !== true ? (
            <SubmitButton
              isSubmitting={isSubmitting}
            />
          ) : (
            // Can Be a link form or a single step form
            <Button
              disabled={!isValid}
              className="w-full sm:py-2 lg:py-2 text-md sm:text-sm m-0 sm:mt-2 sm:mb-4 lg:mb-2"
              onClick={async (e) => {
                if (isLinkForm) {
                  e.preventDefault()
                }
                if (window['dataLayer']) {
                  window['dataLayer'].push({ event: 'nextClicked' })
                }
                setFieldValue('referrerSource', document.referrer, false)

                const variation = sessionStorage.getItem('experimentVariation');
                const linkParams: any = { ...query, ...ymmValues };

                if (variation) {
                  linkParams['variation'] = variation;
                }

                isLinkForm
                  ? goToLink(serializeLinkWithParams(`https://${CUSTOMER_WEBAPP_URL}/${linkTarget}`, linkParams))
                  : goNextPage()
              }}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </Form>
  )
}
