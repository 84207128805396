import * as React from 'react'
import { Field, FormikProps } from 'formik'
import { CustomTextField, CustomNumberField, PhoneNumberField } from '../../Fields/CustomTextField'
import { object } from 'yup'
import * as Yup from 'yup'
import { CustomReferralSelectField } from '../../Fields/CustomSelectField'
import { referrals, serviceUrgencyOptions } from '../../../data/referrals'

const NoEmailPersonalInfoFormSection: React.FC<FormikProps<any>> = () => {
  return (
    <div className="w-full">
      <div className="mb-4 flex flex-col">
        <Field
          name={'symptoms'}
          component={CustomTextField}
          as={'textarea'}
          customLabel={'How can we help?'}
          subLabel={'Please list any vehicle symptoms or desired services.'}
        />
        <hr className="my-4" />
        <Field name={'fullName'} component={CustomTextField} required={true} />

        <Field
          name={'phoneNumber'}
          component={PhoneNumberField}
          required={true}
          label={"Phone Number (We'll text you your quote)"}
        />
        <Field
          name={'zip'}
          component={CustomNumberField}
          placeholder={'Zip Code'}
          required={true}
          maxLength={5}
        />
        <Field
          name={'serviceUrgency'}
          component={CustomReferralSelectField}
          customLabel={'How soon do you need service?'}
          required={false}
          options={serviceUrgencyOptions}
        />
        <Field
          name={'hearAboutUs'}
          component={CustomReferralSelectField}
          options={referrals}
          customLabel={'Where did you hear about us?'}
          placeholder="Google, Facebook, etc."
          required={false}
        />
      </div>
    </div>
  )
}

export const NoEmailPersonalInfoFormSectionInline: React.FC<FormikProps<any>> = () => {

  return (
    <div className="w-full">
      <div className="mb-4 flex flex-col">
        <div className="px-2">
          <Field
            name={'symptoms'}
            component={CustomTextField}
            as={'textarea'}
            customLabel={'How can we help?'}
            subLabel={'Please list any vehicle symptoms or desired services.'}
          />
        </div>
        <hr className="my-4" />
        <div className="px-2">
          <Field name={'fullName'} component={CustomTextField} required={true} />
        </div>

        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 px-2">
            <Field
              name={'phoneNumber'}
              component={PhoneNumberField}
              required={true}
              label={'Phone Number'}
            />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <Field
              name={'zip'}
              component={CustomNumberField}
              placeholder={'Zip Code'}
              required={true}
              maxLength={5}
            />
          </div>
          <div className="w-full md:w-1/2 px-2 leading-tight">
            <Field
              name={'serviceUrgency'}
              component={CustomReferralSelectField}
              customLabel={'How soon do you need service?'}
              required={false}
              options={serviceUrgencyOptions}
            />
          </div>
          <div className="w-full md:w-1/2 px-2 leading-tight">
            <Field
              name={'hearAboutUs'}
              component={CustomReferralSelectField}
              options={referrals}
              customLabel={'Where did you hear about us?'}
              placeholder="Google, Facebook, etc."
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const PersonalInfoValidationSchema = object().shape({
  fullName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required').length(12, 'Phone Number must be 10 characters'),
  zip: Yup.string().required('Required').length(5, 'Zip code must be 5 digits.'),
  serviceUrgency: Yup.string(),
  hearAboutUs: Yup.string(),
})
export default NoEmailPersonalInfoFormSection
