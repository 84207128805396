import { Field, Form, Formik, FormikProps } from 'formik'
import { useFormAnalytics } from '../../../hooks/useFormAnalytics'
import { Button } from '../../Button'
import React, { useState } from 'react'
import { WizardScheduleServiceValues } from './NoEmailScheduleServiceFormVariation'
import { CUSTOMER_WEBAPP_URL } from '../../../pages/_app'
import { formatQueryParams, useQueryParam } from '../../../hooks/useQueryParam'
import { FormPages } from './Wizard'

export const WizardVariation: React.FC<{
  initialValues: WizardScheduleServiceValues
  title?: string
  subtitle?: string
  formPages: FormPages[]
}> = ({ initialValues, formPages, title, subtitle }) => {
  const [page] = useState(0)
  const activePage = formPages[page].element
  const length = formPages.length
  const validationSchema = formPages[page].validationSchema
  return (
    <div className={'text-left text-black'}>
      <div className="text-center">
        <h3>{title ?? `Get A Free Quote Now`}</h3>
        <p>{subtitle ?? `Our repair experts will send you a quote in under 5 minutes.`}</p>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={() => {
          console.log('submitted')
        }}
      >
        {(props) => <InnerForm {...props} activePage={activePage} page={page} length={length} />}
      </Formik>
    </div>
  )
}

const InnerForm: React.FC<
  FormikProps<any> & {
    page: number
    activePage: any
    length: number
  }
> = (props) => {
  const { values, isValid, activePage, setFieldValue } = props
  const query = useQueryParam()
  const queryParams = formatQueryParams(query)

  const symbol = queryParams === '' ? '?' : '&'
  useFormAnalytics(values)

  const trimmedValues = {
    vehicleYear: values?.vehicleYear?.trim(),
    make: values?.make?.trim(),
    model: values?.model?.trim(),
  }

  return (
    <Form name="scheduleServiceWizard">
      <Field type="hidden" name="form-name" value={'scheduleServiceWizard'} />
      <Field type="hidden" name="email" />
      {React.createElement(activePage, props)}
      <div className="px-6">
        <Button
          disabled={!isValid}
          className={`w-full py-3`}
          onClick={async () => {
            setFieldValue('referrerSource', document.referrer, false)

            if (window['dataLayer'] && window['location']) {
              window['dataLayer'].push({ event: 'nextClicked' })
              window['location'].assign(
                `https://${CUSTOMER_WEBAPP_URL}/get-a-quote/services${queryParams}${symbol}vehicleYear=${
                  trimmedValues?.vehicleYear ?? ''
                }&vehicleMake=${trimmedValues.make ?? ''}&vehicleModel=${trimmedValues?.model ?? ''}`
              )
            }
          }}
        >
          Next
        </Button>
      </div>
    </Form>
  )
}
